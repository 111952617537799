<template>
  <div v-if="selectedSalida">
    <b-overlay
      :show="spinner"
      spinner-variant="primary"
    >
      <salidasForm
        :update="true"
        salidaSubmit="Editar Salida"
        :salida="selectedSalida"
        @processSalida="updateSalida"
      />
    </b-overlay>
  </div>
</template>

<script>

import { BOverlay } from 'bootstrap-vue'
import { mapActions, mapState } from 'vuex'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const salidasForm = () => import('@/layouts/components/Salidas/SalidasForm.vue')

export default {
  components: {
    BOverlay,
    salidasForm,
  },
  data() {
    return {
      spinner: false,
    }
  },
  computed: {
    ...mapState('salidas', ['selectedSalida']),
  },
  methods: {
    ...mapActions({ editarsalidas: 'salidas/updateSalidas' }),
    updateSalida(salida) {
      this.spinner = true
      this.editarsalidas(salida).then(() => {
        const errorsalidas = store.state.salidas
        // const errorMessage = errorsalidas.errorMessage.errors
        if (!errorsalidas.error) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Salida editado con éxito 👍',
              text: `El salida "${salida.referencia}" fue editada con éxito!`,
              icon: 'CheckIcon',
              variant: 'success',
            },
          },
          {
            timeout: 4000,
          })
          this.$router.replace({
            name: 'salidas',
          })
        } else if (errorsalidas.error) {
          this.$swal({
            title: 'Error!',
            text: 'Ingreso de datos fraudulento!',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        }
        this.spinner = false
      })
    },
  },
}
</script>
